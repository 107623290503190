import { Card, Row } from 'antd';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { Content } from 'antd/es/layout/layout';
import ConnectButton from '../Components/Buttons';
import { Text } from '../Components/Text';
import metaMaskicon from '../assets/Svg/Metamask.svg';
import WalletConnectSvg from '../assets/Svg/WalletConnect.svg';
import { MAINNET_CHAINS } from '../chains';
import { hooks, metaMask } from '../connectors/metaMask';
import { walletConnectV2 } from '../connectors/walletConnectV2';

const CHAIN_IDS = Object.keys(MAINNET_CHAINS).map(Number);
const {
  useChainId,
  useAccounts,
  useIsActivating,
  useIsActive,
  useProvider,
  useENSNames,
} = hooks;

const Login = () => {
  const chainId = useChainId();
  const accounts = useAccounts();
  const isActivating = useIsActivating();
  const isActive = useIsActive();
  const provider = useProvider();
  const ENSNames = useENSNames(provider);

  const { xs } = useBreakpoint();

  const handleMetaMaskConnect = async () => {
    try {
      console.log('called metamask');
      await metaMask.activate();
      console.log('Connected to MetaMask');
    } catch (error) {
      console.error('MetaMask connection error:', error);
    }
  };

  const handleWalletConnectConnect = async () => {
    try {
      console.log('called wallet Connect');
      await walletConnectV2.activate();
      console.log('Connected to WalletConnect');
    } catch (error) {
      console.error('WalletConnect connection error:', error);
    }
  };

  return (
    <Content
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Card
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: xs ? '353px' : '450px',
          backgroundColor: '#171717',
          border: '1px solid #292929 ',
          borderRadius: '12px',
          padding: '12px 0px',
        }}
      >
        <Row style={{ justifyContent: 'center' }}>
          <img src="Frame.svg" style={{ width: '331px', height: '57px' }} />
        </Row>

        <Row style={{ justifyContent: 'center' }}>
          <Text
            style={{
              fontSize: '20px',
              color: 'white',
              fontWeight: '600',
              textAlign: 'center',
              marginTop: '36px',
            }}
          >
            Connect your wallet to continue
          </Text>
          <Text
            style={{
              fontSize: '14px',
              color: '#6E6E6E',
              fontWeight: '400',
              textAlign: 'center',
              marginTop: '16px',
              width: '100%',
            }}
          >
            Choose how you would like to connect your
          </Text>
          <Text
            style={{
              fontSize: '14px',
              color: '#6E6E6E',
              fontWeight: '400',
              textAlign: 'center',

              width: '100%',
            }}
          >
            wallet.
          </Text>{' '}
          <Text
            style={{
              fontSize: '14px',
              color: '#6E6E6E',
              fontWeight: '400',
              textAlign: 'center',
            }}
          >
            We have several options available for you.
          </Text>
        </Row>
        <Row style={{ justifyContent: 'center', marginTop: '36px' }}>
          <ConnectButton
            name="MetaMask"
            icon={metaMaskicon}
            onClick={handleMetaMaskConnect}
          />
          <ConnectButton
            name="Wallet Connect"
            icon={WalletConnectSvg}
            onClick={handleWalletConnectConnect}
            style={{ marginTop: '10px' }}
          />
        </Row>
      </Card>
    </Content>
  );
};

export default Login;
