import { Button, ButtonProps } from 'antd';
import { Text } from './Text';

type prop = {
  name: string;
  icon: string;
  style?: React.CSSProperties;
} & ButtonProps;
const ConnectButton = (props: prop) => {
  const { name, icon, style, ...ButtonProps } = props;
  return (
    <Button
      style={{
        width: '100%',
        height: '52px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: '#292929',
        border: '1px solid #292929',
        ...style,
      }}
      {...ButtonProps}
    >
      <img
        src={icon}
        style={{ marginRight: '16px', height: '32px', width: '32px' }}
      />
      <Text style={{ fontSize: '16px', fontWeight: '600' }}>{name}</Text>
    </Button>
  );
};

export default ConnectButton;
