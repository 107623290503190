import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Switch,
  Table,
  Tag,
} from 'antd';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import type { ColumnsType } from 'antd/es/table';
import type { TableRowSelection } from 'antd/es/table/interface';
import React, { useRef, useState } from 'react';
import { GET_ALL_COINS, UPDATE_COIN } from '../graphql';
import {
  Mutation,
  MutationUpdateCoinArgs,
  Query,
} from '../graphql/__generatedTypes__';
import { Text } from './Text';

interface DataType {
  id: number;
  key: string;
  name: string;
  network?: string | undefined | null;
  address?: string | undefined | null;
  default?: boolean | undefined | null;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'ID',
    dataIndex: 'id',
    sorter: (a, b) => a.id - b.id,
  },
  {
    title: 'NAME',
    dataIndex: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: 'ADDRESS',
    dataIndex: 'address',
    // sorter: (a, b) => a.address.localeCompare(b.address),
  },
  {
    title: 'NETWORK',
    dataIndex: 'network',
    // sorter: (a, b) => a.network.localeCompare(b.network),
  },
  {
    title: 'DEFAULT',
    dataIndex: 'default',
    render: (value: boolean) => (
      <Tag color={value ? '#3B87F7' : '#292929'}>
        {value ? 'True' : 'False'}
      </Tag>
    ),
    sorter: (a, b) => (a.default ? 1 : 0) - (b.default ? 1 : 0),
  },
];

// const data: DataType[] = [];
// for (let i = 0; i < 46; i++) {
//   const uniqueKey = `row_${i}`;
//   data.push({
//     key: uniqueKey,
//     id: i + 1,
//     name: `Edward King ${i}`,
//     address: `0x1d2f0df6c60dbe ${i}`,
//     network: 'BSC',
//     default: i % 2 === 1,
//   });
// }

const TokenTabel = () => {
  const [selectedRowKey, setSelectedRowKey] = useState<React.Key | null>(null);
  const selectedCoinId = useRef<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: allCoins, loading, error } = useQuery<Query>(GET_ALL_COINS);
  // useMutation
  const [updateTokenMutation, { data: updateRes }] = useMutation<
    Mutation,
    MutationUpdateCoinArgs
  >(UPDATE_COIN);

  console.log('all coins::', allCoins?.getAllCoins);

  const onUpdateToken = async (data: MutationUpdateCoinArgs) => {
    try {
      console.log('data in onUpdateToken::', data);
      const res = await updateTokenMutation({
        variables: data,
      });
      console.log('res in onUpdateToken::', res);
    } catch (error) {
      console.error('error in onUpdateToken::', error);
      throw error;
    }
  };

  const [form] = Form.useForm();
  const { lg, md, sm } = useBreakpoint();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    form.resetFields();
    setIsModalOpen(false);
    setSelectedRowKey(null);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
    setSelectedRowKey(null);
  };

  const onFinish = async (values: any) => {
    try {
      console.log('Form values:>>>>>>>>>><><><><>', values);
      const id = Number(selectedCoinId.current);
      if (!id) throw new Error('id is not defined');
      console.log('id in onFinish::', id);
      await onUpdateToken({
        id,
        updateCoinInput: {
          address: values.contractAddress,
          decimals: values.decimals,
          isDefault: values.showDefault,
          // isToken: true,
          // logo: '',
          name: values.name,
          network: values.network,
          symbol: values.symbol,
        },
      });
    } catch (error) {
      console.error('error in onFinish::', error);
    } finally {
      setIsModalOpen(false);
      form.resetFields();
      setSelectedRowKey(null);
    }
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    const selectedRowKey = newSelectedRowKeys[0] || null;
    // console.log('Selected Row Key: ', selectedRowKey);
    setSelectedRowKey(selectedRowKey);
    showModal();
  };

  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys: selectedRowKey ? [selectedRowKey] : [],
    onChange: onSelectChange,
    // Disable the "Select All" checkbox in the header
    hideSelectAll: true,
  };

  const onRow = (record: DataType) => {
    // console.log('record in onRow:><><><:', record);
    return {
      // Handle row click event
      onClick: () => {
        onSelectChange([record.key]);
        // Call your function with the selected row data
        handleRowSelection(record);
      },
    };
  };

  const handleRowSelection = (selectedRow: DataType) => {
    // Implement your logic to handle the selected row data
    console.log('Selected Row Data:>>>>>>>>>>>>>>>>>>>> ', selectedRow);
    // update the form values with the selected row data
    selectedCoinId.current = selectedRow.id;
    form.setFieldsValue({
      network: selectedRow.network,
      contractAddress: selectedRow.address,
      name: selectedRow.name,
      symbol: selectedRow.name,
      decimals: 18,
      showDefault: selectedRow.default,
    });
  };

  const paginationConfig = {
    pageSize: 6,
  };

  // Use API data instead of dummy data
  const data: DataType[] =
    allCoins?.getAllCoins.map((coin) => ({
      key: coin.id.toString(),
      id: Number(coin.id),
      name: coin.name,
      address: coin.address,
      network: coin.network,
      default: coin.isDefault,
    })) || [];
  return (
    <>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        style={{ width: '100%', overflow: 'scroll' }}
        pagination={paginationConfig}
        onRow={onRow}
      />
      <Modal
        width={sm ? 500 : 360}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        closable={false}
        centered
        style={{ border: '1px solid #292929', borderRadius: '12px' }}
      >
        <Row style={{ marginBottom: '32px' }}>
          <Text size="xxxl">Edit Token</Text>
        </Row>

        <Row>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            style={{ width: '100%' }}
          >
            <Form.Item
              name="network"
              rules={[{ message: 'Please select a network' }]}
            >
              <Select
                placeholder="Select a network"
                options={[
                  { value: 'BEP20', label: 'BEP20' },
                  { value: 'ERC20', label: 'ERC20' },
                  { value: 'BEP2', label: 'BEP2' },
                ]}
              />
            </Form.Item>

            <Form.Item name="contractAddress" label="Contract address">
              <Input placeholder="Enter an address" />
            </Form.Item>

            <Form.Item name="name" label="Name">
              <Input placeholder="Enter a name" required />
            </Form.Item>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="symbol" label="Symbol">
                  <Input placeholder="Token Symbol" required />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="decimals" label="Decimals amount">
                  <Input placeholder="Decimals" required />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="showDefault"
                  label="Show this token by default"
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ justifyContent: 'flex-end' }}>
              <Button
                style={{
                  marginRight: '10px',
                  color: 'white',
                  backgroundColor: 'transparent',
                  borderColor: '#4A4A4A',
                }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button htmlType="submit" className="button-primary-hover">
                Save Changes
              </Button>
            </Row>
          </Form>
        </Row>
      </Modal>
    </>
  );
};

export default TokenTabel;
