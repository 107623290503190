import { initializeConnector } from '@web3-react/core'
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'


export const [walletConnectV2, hooks] = initializeConnector(
  (actions) =>
    new WalletConnectV2({
      actions,
      options: {
        projectId: "ac6818d713537310b1b5435748cba3f4",
        chains: [1, 56],
        showQrModal: true,
      },
    })
)
