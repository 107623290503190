import { gql } from '@apollo/client';

export const GET_SIGNATURE = gql`
  query GetSignature($walletAddress: String!) {
    getSignature(walletAddress: $walletAddress) {
      message
    }
  }
`;

export const GET_ALL_COINS = gql`
  query GetAllCoins {
    getAllCoins {
      id
      name
      symbol
      address
      decimals
      network
      createdAt
      updatedAt
      isDefault
      coinInfo {
        id

        cmcId
        name
        symbol
        slug
        num_market_pairs
        tags
        max_supply
        circulating_supply
        total_supply
        cmc_rank
        infinite_supply
        is_active
        is_fiat
        createdAt
        updatedAt
        platform {
          id
          token_address
          name
          symbol
          slug
        }
        quote {
          USD {
            currency
            price
            volume_24h
            volume_change_24h
            percent_change_1h
            percent_change_24h
            percent_change_7d
            percent_change_30d
            percent_change_60d
            percent_change_90d
            market_cap
            market_cap_dominance
            fully_diluted_market_cap
            tvl
            last_updated
          }
        }
      }
    }
  }
`;

export const GET_COIN_BY_ID = gql`
  query Coin($coinId: Float!) {
    getCoinById(id: $coinId) {
      id
      name
      symbol
      address
      decimals
      network
      createdAt
      updatedAt
      coinInfo {
        id
        cmcId
        name
        symbol
        slug
        num_market_pairs
        tags
        max_supply
        circulating_supply
        total_supply
        cmc_rank
        infinite_supply
        is_active
        is_fiat
        createdAt
        updatedAt
        platform {
          id
          token_address
          name
          symbol
          slug
        }
        quote {
          USD {
            currency
            price
            volume_24h
            volume_change_24h
            percent_change_1h
            percent_change_24h
            percent_change_7d
            percent_change_30d
            percent_change_60d
            percent_change_90d
            market_cap
            market_cap_dominance
            fully_diluted_market_cap
            tvl
            last_updated
          }
        }
      }
    }
  }
`;

export const GET_COIN_INFO = gql`
  query GetCoinInfo($symbol: [String!], $address: [String!]) {
    getCoinInfo(symbol: $symbol, address: $address) {
      id
      name
      symbol
      address
      decimals
      network
      createdAt
      updatedAt
      coinInfo {
        id
        cmcId
        name
        symbol
        slug
        num_market_pairs
        tags
        max_supply
        circulating_supply
        total_supply
        cmc_rank
        infinite_supply
        is_active
        is_fiat
        createdAt
        updatedAt
        platform {
          id
          token_address
          name
          symbol
          slug
        }
        quote {
          USD {
            currency
            price
            volume_24h
            volume_change_24h
            percent_change_1h
            percent_change_24h
            percent_change_7d
            percent_change_30d
            percent_change_60d
            percent_change_90d
            market_cap
            market_cap_dominance
            fully_diluted_market_cap
            tvl
            last_updated
          }
        }
      }
    }
  }
`;

export const GET_LOGGED_IN_USER = gql`
  query LoggedInUser {
    loggedInUser {
      id
      walletAddress
      deviceToken
      deviceType
      role
      isPrivateKey
      walletName
      createdAt
      updatedAt
    }
  }
`;
