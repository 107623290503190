// export const SERVER_URL_GQL = 'https://dev.api.shido-gql.baboons.tech/graphql';
// export const SERVER_URL_GQL = 'http://localhost:3000/graphql';
export const SERVER_URL_GQL = 'https://api.gql.shido.io/graphql';

export const colors = {
  //App Colors
  Primary: '#4ADC8C',
  black: '#000',
  Grey: '#CDCDCD',
  white: '#FFF',
  MidGrey: '#333',
  DarkGrey: '#191919',
  NotChecked: '#446DFF',
  Status: '#F44',
  InProgress: '#FF9344',
  Checked: '#4ADC8C',

  //primary: '#0086FF',
  primary50: '#FCFEFF',
  primary100: '#F5FAFF',
  primary300: '#A3D3FF',
  primary500: '#0086FF',
  primary600: '#006BCC',
  primary700: '#005099',
  primary800: '#003666',
  primary900: '#001B33',

  success: '#50B17F',
  successAccent: '#E9FDF2',
  error: '#EA332D',
  errorAccent: '#FDF6E9',
  warning: '#F2AB3C',
  warningAccent: '#FBE9E9',

  //secondary
  secondary: '#050505',
  secondary50: '#F1F2FC',
  secondary100: '#DDDEE8', //todo-->match this with figma
  secondary200: '#CCCDD6',
  secondary300: '#B6B7BF',
  secondary400: '#9B9CA3',
  secondary500: '#7C7C82',
  secondary600: '#37373C', //todo-->match this with figma
  secondary700: '#252527',
  secondary800: '#181819',
  secondary900: '#050505',
};
