import { useLazyQuery, useMutation } from '@apollo/client';

import { useWeb3React } from '@web3-react/core';
import { GET_LOGGED_IN_USER, GET_SIGNATURE, LOGIN, REGISTER } from '../graphql';
import {
  Mutation,
  MutationLoginArgs,
  MutationRegisterArgs,
  Query,
  QueryGetSignatureArgs,
} from '../graphql/__generatedTypes__';

const useUser = () => {
  const { isActive, account, provider, hooks } = useWeb3React();

  const [registerMutation] = useMutation<Mutation, MutationRegisterArgs>(
    REGISTER,
  );
  const [getSignatureQuery] = useLazyQuery<Query, QueryGetSignatureArgs>(
    GET_SIGNATURE,
  );
  const [getLogedInuser, { data: loggedInUser }] =
    useLazyQuery<Query>(GET_LOGGED_IN_USER);
  //useMutations
  const [loginMutation] = useMutation<Mutation, MutationLoginArgs>(LOGIN);

  const register = async ({
    walletAddress,
    walletName,
  }: {
    walletAddress: string;
    walletName: string;
  }) => {
    try {
      console.warn('register', walletAddress, walletName);
      const res = await registerMutation({
        variables: {
          registerInputData: {
            deviceToken: 'token',
            deviceType: 1,
            isPrivateKey: 1,
            walletAddress,
            walletName,
          },
        },
      });
      console.log('register', res.data?.register);
      return res.data?.register;
    } catch (error) {
      console.error('error in register', error);
    }
  };

  const loginGql = async ({
    message,
    walletAddress,
  }: {
    message: string;
    walletAddress: string;
  }) => {
    try {
      const now = new Date().getTime();
      console.log('signing message time start', now);
      if (!provider || !account) {
        throw 'Account is not connected';
      }
      const signature = await provider.send('personal_sign', [
        message,
        account,
      ]);
      // const signedMessage = await signMessage(message);
      console.log('signing message time elapsed', new Date().getTime() - now);
      if (!signature) throw new Error('signedMessage error');
      const res = await loginMutation({
        variables: {
          userData: {
            challenge: message,
            signature: signature,
            walletAddress,
          },
        },
      });
      console.log('loginGql', res.data?.login);
      localStorage.setItem('accessToken', res.data?.login?.accessToken || '');
      localStorage.setItem(
        'refreshAccessToken',
        res.data?.login?.refreshToken || '',
      );

      return res.data?.login;
    } catch (error) {
      console.error('error in loginGql', error);
      throw error;
    }
  };
  const getSignature = async ({ walletAddress }: { walletAddress: string }) => {
    try {
      const res = await getSignatureQuery({
        variables: {
          walletAddress,
        },
      });
      console.log('getSignatureQuery', res.data?.getSignature);
      if (!res.data?.getSignature) throw new Error('getSignature error');
      const reslogin = await loginGql({
        message: res.data.getSignature.message,
        walletAddress,
      });
      return reslogin;
    } catch (error) {
      console.error('error in getSignature', error);
      throw error;
    }
  };

  const signup = async (param: {
    ethAddress: string;
    name: string;
    btcAddress?: string;
  }) => {
    try {
      const { btcAddress, ethAddress, name } = param || {};

      //shido-gql

      const resReg = await register({
        walletAddress: ethAddress,
        walletName: name,
      });
      if (resReg) {
        const res = await getSignature({ walletAddress: ethAddress });
        if (res) {
          return res;
        }
      }

      throw 'Some thing went wrong';
    } catch (error) {
      console.log('err createWallet', error);
      throw error;
    }
  };

  return { signup, getLogedInuser };
};

export default useUser;
