import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from 'apollo-link-context';
import { SERVER_URL_GQL } from '../utils';

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, response }) => {
    if (graphQLErrors || networkError || response) {
      operation.query.definitions.forEach((definition) => {
        const op = 'operation' in definition ? definition.operation : '';
        console.log(
          `[Operation Shido] apollo ${op} ${operation.operationName || ''}`,
        );
      });
    }

    if (response) {
      console.log(`[Operation Result Shido] ${JSON.stringify(response)}`);
    }

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        const locationsStr = JSON.stringify(locations);

        console.warn(
          `[GraphQL Error Shido] Message: "${message}", Locations: ${locationsStr}, Path: "${path}"`,
        );
      });
    }

    if (networkError) {
      console.error(`[Network Error Shido] "${networkError}"`);
    }
  },
);

const httpLink = new HttpLink({
  uri: SERVER_URL_GQL,
});

const authMiddleware = setContext((operation) => {
  const accessToken = localStorage.getItem('accessToken');
  return {
    headers: {
      authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  };
});

const client = new ApolloClient({
  link: from([errorLink, authMiddleware as unknown as ApolloLink, httpLink]),
  cache: new InMemoryCache(),
  // connectToDevTools: true,
});
export default client;
