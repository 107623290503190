import {
  EuroOutlined,
  HomeOutlined,
  LogoutOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import { useWeb3React } from '@web3-react/core';
import { Layout, Menu, MenuProps, Row, theme } from 'antd';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { closeSidebar, openSidebar } from '../redux/reducers';
import { Text } from './Text';

const { Sider } = Layout;
const { useToken } = theme;

export default function Sidebar() {
  const mobileSider = useSelector((state: any) => state.app.MobileSider);
  const { token } = useToken();
  const { lg, md } = useBreakpoint();
  const dispatch = useDispatch();
  const onClick = (e: any) => {
    console.log('click ', e);
  };

  type MenuItem = Required<MenuProps>['items'][number];

  const getItem = (
    label: string,
    key: string,
    to?: string | null,
    icon?: React.ReactElement | null,
    children?: any,
    type?: string,
  ): MenuItem => {
    return {
      key,
      icon,
      children,
      type,
      label: to ? <Link to={to}>{label}</Link> : label,
      onClick: () => {
        dispatch(closeSidebar());
      },
    } as MenuItem;
  };

  const items = [
    getItem('Dashboard', '1', '/', <HomeOutlined />),
    getItem('Tokens', '2', '/Tokens', <EuroOutlined />),
  ];

  const { isActive, account, connector } = useWeb3React();

  return (
    <Sider
      className={mobileSider ? 'sider-for-mobile' : ''}
      width={lg ? 325 : md ? 250 : mobileSider ? 250 : 0}
      reverseArrow={true}
      style={{
        zIndex: 99,
        overflow: 'auto',
        alignItems: 'center',
        backgroundColor: '#171717',
        borderRadius: '12px',
        marginRight: md ? 24 : 0,
      }}
      trigger={null}
    >
      <Row
        style={{
          paddingLeft: '20px',
          paddingRight: '20px',
          marginBottom: '26px',
          position: 'relative',
        }}
      >
        <img src="LogoPlaceholder.svg" style={{ width: lg ? 281 : 206 }}></img>
        <div
          style={{
            display: mobileSider ? 'flex' : 'none',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(59, 135, 247, 0.10)',
            height: '42px',
            width: '42px',
            borderRadius: '12px',
            cursor: 'pointer',
            position: 'absolute',
            right: 14,
            top: 22,
          }}
          onClick={() => {
            if (mobileSider) {
              dispatch(closeSidebar());
            } else {
              dispatch(openSidebar());
            }
            console.log('tauseefCheck', mobileSider);
          }}
        >
          <MenuOutlined style={{ color: '#3B87F7' }} />
        </div>
      </Row>
      <Menu
        onClick={onClick}
        mode="inline"
        items={items}
        className={'scroll-container'}
        style={{
          maxHeight: '70vh',
          overflowY: 'auto',
          padding: '0 12px',
          backgroundColor: '#171717',
          color: '#B3B3B3',
        }}
      />

      <Row
        align={'middle'}
        style={{
          position: 'absolute',
          bottom: '2em',
          margin: '0 36px',
          gap: '14px',
          cursor: 'pointer',
        }}
        onClick={() => {
          localStorage.clear();
          if (connector?.deactivate) {
            connector.deactivate();
          } else {
            connector.resetState();
          }
        }}
      >
        <LogoutOutlined style={{ color: '#B3B3B3', marginTop: '3px' }} />
        <Text size="lg" style={{ color: '#B3B3B3' }}>
          {' '}
          Logout
        </Text>
      </Row>
    </Sider>
  );
}
