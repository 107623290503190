import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import "./App.css";
import { Layout } from "antd";
import { userState } from "./redux/reducers/userSlice";
import { useSelector } from "react-redux";
import { ConfigProvider } from "antd";
import Main from "./Main/Main";

const { Content } = Layout;
const router = createBrowserRouter(
  createRoutesFromElements(<Route path="*" element={<Main />} />)
);
const App = () => {
  const user = useSelector(userState);
  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Menu: {
              itemSelectedBg: "#3B87F7",
              itemSelectedColor: "white",
              fontSize: 16,
              iconSize: 16,
              borderRadius: 4,
              itemBorderRadius: 8,
              iconMarginInlineEnd: 12,
              itemHeight: 44,
              itemHoverColor: "white",
            },
            Input: {
              colorBgContainer: "#171717",
              colorBorder: "#4A4A4A",
              hoverBorderColor: "#4A4A4A",
              activeBorderColor: "#4A4A4A",
              activeShadow: "none",
              colorText: "white",
              colorTextPlaceholder: "#4A4A4A",
            },
            Button: {
              colorBgContainer: "#3B87F7",
              colorText: "#0C0D0E",
              colorPrimaryHover: "#3B87F7",
              fontSize: 16,
              defaultBorderColor: "#3B87F7",
              fontWeight: 600,
              controlHeight: 40,
            },
            Table: {
              borderColor: "#4A4A4A",
              rowSelectedBg: "#102441",
              rowSelectedHoverBg: "#102441",
              headerColor: "#5C5C5C",
              colorIcon: "#5C5C5C",
              headerSplitColor: "#5C5C5C",
              headerBg: "#171717",
              colorBgContainer: "transparent",
              colorText: "#E2E2E2",
            },
            Pagination: {
              itemActiveBg: "transparent",
              colorText: "#E2E2E2",
            },
            Checkbox: {
              colorBgContainer: "transparent",
              colorBorder: "#383838",
            },
            Modal: {
              contentBg: "#171717",
            },
            Form: {
              labelColor: "#FFFFFF",
            },
            Select: {
              colorBgContainer: "#171717",
              colorBorder: "#4A4A4A",
              colorPrimaryHover: "#4A4A4A",
              colorPrimary: "#4A4A4A",
              colorText: "white",
              colorTextPlaceholder: "#4A4A4A",
              colorBgElevated: "#171717",
              controlItemBgActive: "#3B87F7",
              colorIcon: "white",
            },
          },
        }}
      >
        <RouterProvider router={router} />
      </ConfigProvider>
    </>
  );
};

export default App;
