import { PlusCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  Row,
  Select,
  Switch,
} from 'antd';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { useState } from 'react';
import { Text } from '../Components/Text';
import TokenTabel from '../Components/TokenTabel';

const { Content } = Layout;
const Tokens = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { lg, md, sm } = useBreakpoint();
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const onFinish = (values: any) => {
    console.log('Form values:', values);
    setIsModalOpen(false);
    form.resetFields();
  };

  return (
    <>
      <Row>
        <Col
          span={24}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Text size="xxxl" style={{ fontWeight: 600 }}>
            All tokens
          </Text>
          <Button
            icon={<PlusCircleOutlined />}
            onClick={showModal}
            className="button-primary-hover"
          >
            Add token
          </Button>
        </Col>
      </Row>
      <Row style={{ marginTop: '16px' }}>
        <TokenTabel />
      </Row>

      <Modal
        width={sm ? 500 : 360}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        closable={false}
        centered
        style={{ border: '1px solid #292929', borderRadius: '12px' }}
      >
        <Row style={{ marginBottom: '32px' }}>
          <Text size="xxxl">Add Token</Text>
        </Row>

        <Row>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            style={{ width: '100%' }}
          >
            <Form.Item
              name="network"
              rules={[{ required: true, message: 'Please select a network' }]}
            >
              <Select
                placeholder="Select a network"
                options={[
                  { value: 'ERC20', label: 'ERC20' },
                  { value: 'BEP20', label: 'BEP20' },
                  { value: 'BEP2', label: 'BEP2' },
                ]}
              />
            </Form.Item>

            <Form.Item name="contractAddress" label="Contract address">
              <Input placeholder="Enter an address" required />
            </Form.Item>

            <Form.Item name="name" label="Name">
              <Input placeholder="Enter a name" required />
            </Form.Item>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="symbol" label="Symbol">
                  <Input placeholder="Token Symbol" required />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="decimals" label="Decimals amount">
                  <Input placeholder="Decimals" required />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="showDefault"
                  label="Show this token by default"
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ justifyContent: 'flex-end' }}>
              <Button
                style={{
                  marginRight: '10px',
                  color: 'white',
                  backgroundColor: 'transparent',
                  borderColor: '#4A4A4A',
                }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button htmlType="submit" className="button-primary-hover">
                Add new token
              </Button>
            </Row>
          </Form>
        </Row>
      </Modal>
    </>
  );
};

export default Tokens;
