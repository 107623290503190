import { MenuOutlined } from '@ant-design/icons';
import { useWeb3React } from '@web3-react/core';
import { Button, Col, Input, Layout, Row, theme } from 'antd';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { useDispatch, useSelector } from 'react-redux';
import { closeSidebar, openSidebar } from '../redux/reducers';

const { Header } = Layout;
const { useToken } = theme;

export const TopHeader = () => {
  const dispatch = useDispatch();
  const mobileSider = useSelector((state: any) => state.app.MobileSider);
  const { token } = useToken();
  const { lg, md } = useBreakpoint();
  const { isActive, account } = useWeb3React();
  return (
    <Header
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '44px 24px',
        backgroundColor: '#171717',
        borderRadius: '12px',
      }}
    >
      <Row style={{ width: '100%' }}>
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Input
            placeholder="Search"
            style={{ maxWidth: lg ? 439 : 276, display: md ? '' : 'none' }}
          />
          <div
            style={{
              display: md ? 'none' : 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(59, 135, 247, 0.10)',
              height: '42px',
              width: '42px',
              borderRadius: '12px',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (mobileSider) {
                dispatch(closeSidebar());
              } else {
                dispatch(openSidebar());
              }
              console.log('tauseefCheck', mobileSider);
            }}
          >
            <MenuOutlined style={{ color: '#3B87F7' }} />
          </div>

          <Button className="button-primary-hover">
            {account ? account : 'Connect Wallet'}
          </Button>
        </Col>
      </Row>
    </Header>
  );
};

export default TopHeader;
