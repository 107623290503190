import { useWeb3React } from '@web3-react/core';
import { Layout, Spin } from 'antd';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import Login from '../Pages/Login';
import Tokens from '../Pages/Tokens';
import useUser from '../hooks/useUser';

const { Content } = Layout;

//test commit

const Main = () => {
  const { isActive, account, provider, hooks } = useWeb3React();

  useEffect(() => {
    if (isActive) {
      console.log('isActive ', isActive);
      console.log('account ', account);
      console.log('provider ', provider);
      console.log('hooks ', hooks);
    }
  }, [isActive]);
  return (
    <Layout
      style={{
        minHeight: '100vh',
        backgroundColor: '#0C0D0E',
      }}
    >
      {/* {user.isAuthenticated ? <LoggedInRoutes /> : <NotLoggedInRoutes />} */}
      {isActive ? <LoggedInRoutes /> : <NotLoggedInRoutes />}
    </Layout>
  );
};

export default Main;

const LoggedInRoutes = () => {
  const mobileSider = useSelector((state: any) => state.app.MobileSider);
  const { sm, md } = useBreakpoint();
  const { signup, getLogedInuser } = useUser();
  const { isActive, account, connector } = useWeb3React();

  const [loader, setLoader] = useState(true);
  // const authToken = useReactiveVar(AuthToken);

  const reset = async () => {
    if (connector?.deactivate) {
      await connector.deactivate();
    } else {
      await connector.resetState();
    }
  };

  useEffect(() => {
    if (account) {
      signup({
        ethAddress: account,
        name: 'Dummy',
        btcAddress: '',
      })
        .then(async (res) => {
          console.log('signup response >>>>>', res);
          //AuthToken(res.accessToken);
          const loggedInUser = await getLogedInuser();
          console.log('loggedInUSer', loggedInUser.data);
          if (loggedInUser.data?.loggedInUser.role === 'admin') {
            setLoader(false);
          } else {
            throw 'Not admin wallet';
          }
        })
        .catch((error) => {
          console.log(error);
          console.log('before >>> ' + isActive);
          reset().then(() => {
            console.log('before >>> ' + isActive);
          });
        });
    }
  }, [account]);
  if (loader) {
    return (
      <div
        //
        style={{
          position: 'absolute',
          right: '50%',
          top: '50%',
        }}
      >
        <Spin size="large" />
      </div>
    );
  }
  return (
    <Layout
      style={{
        minHeight: '100vh',
        backgroundColor: '#0C0D0E',
        padding: md ? '24px' : '12px',
      }}
    >
      <Sidebar />

      <Layout
        style={{ backgroundColor: '#0C0D0E', gap: '24px' }}
        className={mobileSider ? 'overlay' : ''}
      >
        <Header />
        <Content
          style={{
            backgroundColor: '#171717',
            borderRadius: '12px',
            padding: md ? '24px' : '12px',
          }}
        >
          <Tokens />
        </Content>
      </Layout>
    </Layout>
  );
};

const NotLoggedInRoutes = () => {
  const { isActive, account, connector } = useWeb3React();

  useEffect(() => {
    console.log('LOgin >>>>>>>>>>>> ' + isActive);
  });

  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#0C0D0E' }}>
      <Login />
    </Layout>
  );
};
